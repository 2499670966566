// TODO: fix in https://app.asana.com/0/1201994806276539/1205107468358935/f
// eslint-disable-next-line import/named
import { Route, Switch } from 'react-router-dom';

import PublicRouter from './routers/public';

export function Routers() {
    return (
        // @ts-ignore
        <Switch>
            {PublicRouter.map((route) => (
                // @ts-ignore
                <Route key={route.key} path={route.path} render={route.render} exact={route.exact} />
            ))}
        </Switch>
    );
}
